body {
	overflow-x: hidden;
}

body[dir='rtl'] {
	font-family: 'Cairo', sans-serif;
}

body[dir='ltr'] {
	font-family: 'Coda', cursive;
}

.business {
	background-image: url('./assets/contact-bg.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
}

.services {
	background-image: url('./assets/service_bg.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-size: cover;
	padding-bottom: 5rem !important;
}

.responsive__paragraph {
	margin: auto;
	text-align: center;
	font-size: 1.4rem;
}

.navbar-toggler {
	padding: 0 !important;
	line-height: 0 !important;
	border-radius: 0 !important;
	border-color: rgba(0, 0, 0, 0) !important;
	font-size: 1.5rem !important;
}

.navbar-toggler:focus {
	outline: none;
}

.active-nav-home {
	background-color: rgba(126, 72, 112, 0.91) !important;
	-webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-o-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	transition: background-color 0.4s ease;
}
/* .dropdown-menu */

.active-nav-mobile {
	background-color: rgba(69, 143, 212, 0.91) !important;
	-webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-o-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	transition: background-color 0.4s ease;
}

.active-nav-iot {
	background-color: rgba(87, 62, 91, 0.91) !important;
	-webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-o-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	transition: background-color 0.4s ease;
}

.active-nav-web {
	background-color: rgba(206, 66, 66, 0.91) !important;
	-webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-o-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	transition: background-color 0.4s ease;
}

.active-nav-erp {
	background-color: rgba(36, 178, 155, 0.91) !important;
	-webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-o-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	transition: background-color 0.4s ease;
}

.active-nav-business {
	background-color: rgba(241, 183, 81, 0.91) !important;
	-webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	-o-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	transition: background-color 0.4s ease;
}

.dropdown-menu a {
	font-weight: bold;
}

.bg-transparent {
	transition: background-color 0.4s ease;
}

input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number']::-moz-inner-spin-button {
	-moz-appearance: none;
}

input[type='number']::-o-inner-spin-button {
	-o-appearance: none;
}

input[type='number']::-ms-inner-spin-button {
	-ms-appearance: none;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	html {
		font-size: 14px !important;
	}

	.overflow-container {
		overflow-x: auto;
	}
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
	.container {
		width: 100% !important;
		max-width: 100% !important;
		padding: 0 40px;
	}

	.overflow-container {
		overflow-x: auto;
	}
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	.container {
		width: 100% !important;
		max-width: 100% !important;
		padding: 0 50px;
	}

	.overflow-container {
		overflow-x: auto;
	}

	.responsive__paragraph {
		max-width: 50%;
	}
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
	.container {
		width: 100% !important;
		max-width: 100% !important;
		padding: 0 80px;
	}

	.responsive__paragraph {
		max-width: 60%;
	}
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.container {
		width: 100% !important;
		max-width: 100% !important;
		padding: 0 100px;
	}

	.responsive__paragraph {
		max-width: 45%;
	}

	.inline-dropdown {
		display: flex;
	}

	body[dir='ltr'] .navbar-nav .dropdown-menu {
		left: -572%;
		box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	}

	body[dir='rtl'] .navbar-nav .dropdown-menu {
		right: -713%;
		box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
	}
}

/* // left: 50%;
// transform: translateX(-13.5%);
// display: flex;
// text-align: center;
// align-items: center;
// justify-content: space-around; */
