.custom-colorbtn {
	background: linear-gradient(
		105deg,
		#7e4870,
		#458fd4,
		#573e5b,
		#ce4242,
		#24b29b,
		#f1b751,
		#f1b751,
		#24b29b,
		#ce4242,
		#573e5b,
		#458fd4,
		#7e4870
	);
	background-size: 1000% 1000%;
	animation: rainbow 6s linear infinite;
	-webkit-animation: rainbow 6s linear infinite;
	animation-direction: alternate;
	-z-animation: rainbow 6s linear infinite;
	-o-animation: rainbow 6s linear infinite;
	border: 0;
	color: #fff;
	padding: 10px 15px;
	font-size: 1rem;
}
@-webkit-keyframes rainbow {
	0% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}
@-moz-keyframes rainbow {
	0% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}
@-o-keyframes rainbow {
	0% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}
@keyframes rainbow {
	0% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}
